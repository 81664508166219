@import './transitions.css';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Roboto', sans-serif;
}

.bg-decorative {
  /* background-image: url('../public/decorative-background2.svg'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

header {
  transition: all 0.3s ease-in-out;
}

.header-scrolled {
  background-color: rgb(253, 254, 255); /* Slightly transparent blue background */
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
